export const MenuItems = [
    {
        title: 'Home',
        url: '/dashboard',
        cName: 'Nav__links'
    },
    {
        title: 'Webapps',
        url: '/webapps',
        cName: 'Nav__links'
    },
    {
        title: 'CRM',
        url: '/crm',
        cName: 'Nav__links'
    },
    {
        title: 'Bot',
        url: '/bots',
        cName: 'Nav__links'
    },
    {
        title: 'Landings',
        url: '/landingpages',
        cName: 'Nav__links'
    },
    {
        title: 'Apps',
        url: '/apps',
        cName: 'Nav__links'
    }
];
