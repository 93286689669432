import React from "react";
import Avatar from "../../images/svg/AvatarMaker.svg";
import ScrollAnimation from "react-animate-on-scroll";
import developer from "../../images/svg/development.svg";
export function About() {
  return (
    <>
      <div className="about" id="about">
        <div className="about__container">
          <div className="about__data">
            <div className="about__description">
              <img src={Avatar} alt=".." className="Avatar" />
              <h1>Web Developer</h1>
              <h2>Desarrollo cosas simples, y amo lo que hago.</h2>
            </div>
          </div>
        </div>

        <div className="Wrapper"></div>
        <ScrollAnimation animateIn="animate__fadeIn">
          <img src={developer} alt=".." />

          <div className="info">
            <ScrollAnimation animateIn="animate__zoomIn">
              <h3>
                Hola, soy Eric y es un gusto conocerte.
              </h3>
              <p>
                Desarrollador web con diez años de experiencia en el ramo de aplicaciones web trabajando
                en áreas de Fábrica de software, testing, diseño web y mobile. Así como para sectores
                financieros, telecomunicaciones y marketing.
              </p>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
      <div className="Wrapper"></div>
    </>
  );
}
