import React from "react";
import axios from "axios";
import { Paper, Grid, Container, TextField, InputAdornment, FormControl } from "@material-ui/core";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SubjectIcon from '@material-ui/icons/Subject';
import MessageIcon from '@material-ui/icons/Message';
import sendmessage from "../images/svg/sendmessage.svg";
import freelancer from "../images/svg/freelancer.svg";
import CircularProgress from '@material-ui/core/CircularProgress';

export class ContactMe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            loading: false,
            info: '',
            disabled: false,
            className: "button_form",
            value: 0,
            errors: [],
        };
    }
    onNameChange(event) {
        this.setState({ name: event.target.value });
    }

    onEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    onSubjectChange(event) {
        this.setState({ subject: event.target.value });
    }

    onMsgChange(event) {
        this.setState({ message: event.target.value });
    }

    submitEmail(e) {
        e.preventDefault();
        if (this.state.email) {
            let email = this.state.email;
            if (typeof (email) !== "undefined") {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(email)) {
                    this.setState({ errors: "Por favor ingresa un correo electrónico válido." });
                    this.nameInput.focus();
                    return false;
                } else {
                    this.setState({ errors: "" });
                }
                if (this.state.value === 2) {
                    this.setState({
                        loading: false,
                        disabled: true,
                        className: "button_form_block",
                        info: 'Lo siento han sido demasiados intentos. si deseas consultarme marca al siguiente número de CDMX : 5514103024',
                    });
                    return false;
                } else {
                    this.setState({
                        loading: true,
                        disabled: true,
                        className: "button_form_block",
                        value: this.state.value + 1
                    });
                    this.sendForm();
                }

            }
        }
    }
    sendForm() {
        axios({
            method: "POST",
            url: "https://servermusic.herokuapp.com/api/send/",
            data: this.state
        }).then((response) => {
            if (response.data.status === 'success') {
                this.setState({
                    statusCode: true,
                    loading: false,
                    info: 'Tu mensaje se envio correctamente.',
                    disabled: false,
                    className: "button_form",
                });
                this.resetForm();
            } else if (response.data.status === 'fail') {
                this.setState({
                    statusCode: false,
                    loading: false,
                    info: 'Error al enviar el mensaje.',
                    disabled: false,
                    className: "button_form",
                });
            }
        }).catch(err => {
            this.setState({
                statusCode: false,
                loading: false,
                info: 'Error en servidor.',
                disabled: true,
                className: "button_form_block",
            });
        });
    }

    resetForm() {
        this.setState({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
    }

    render() {
        return (
            <>
                <Container fixed>
                    <Paper className="paper">
                        <div className="contactme__form">
                            <img src={sendmessage} alt="..." />
                            <h2>Contáctame</h2>
                            <p>Hola dejame tu mensaje si tienes en
                            puerta algún proyecto que quieras iniciar, o simplemente un saludo.
                            Estoy para servirte.
                            </p>
                            <FormControl>
                                <form id="contact-form" onSubmit={this.submitEmail.bind(this)} method="POST">
                                    <Grid container spacing={3} direction="row">
                                        <Grid item md={6} sm={12} xs={12}>
                                            <TextField
                                                id="name"
                                                label="Nombre"
                                                multiline
                                                fullWidth
                                                rowsMax={6}
                                                value={this.state.name}
                                                onChange={this.onNameChange.bind(this)}
                                                required
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AccountCircle />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12} xs={12}>
                                            <TextField
                                                id="email"
                                                label="E-mail"
                                                multiline
                                                name="email"
                                                fullWidth
                                                rowsMax={4}
                                                onChange={this.onEmailChange.bind(this)}
                                                required
                                                type="email"
                                                ref={input => (this.nameInput = input)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MailOutlineIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={3} direction="row">
                                        <Grid item md={12} sm={12} xs={12}>
                                            <TextField
                                                id="subject"
                                                label="Subject"
                                                multiline
                                                fullWidth
                                                value={this.state.subject}
                                                onChange={this.onSubjectChange.bind(this)}
                                                required
                                                type="text"

                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SubjectIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item md={12} sm={12} xs={12}>
                                            <TextField
                                                id="message"
                                                label="Mensaje"
                                                multiline
                                                fullWidth
                                                value={this.state.message}
                                                onChange={this.onMsgChange.bind(this)}
                                                required
                                                type="text"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MessageIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <br></br>
                                    <Grid container spacing={1} direction="column">
                                        <Grid item md={12} sm={12} xs={12}>
                                            <div className={this.state.className}>
                                                <button disabled={(this.state.disabled) ? "disabled" : ""}>Enviar</button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    {
                                        this.state.loading
                                            ? <CircularProgress></CircularProgress>
                                            : ""
                                    }
                                    <div>
                                        {
                                            this.state.statusCode
                                                ? <span>{this.state.info}</span>
                                                : <span>{this.state.info}</span>
                                        }
                                    </div>
                                    <div className="text-danger">{this.state.errors}</div>
                                </form>
                            </FormControl>
                        </div>
                        <img src={freelancer} alt="..." className="freelancer" />
                    </Paper>
                </Container>
            </>
        );
    }
}