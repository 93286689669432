import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Moons from "../../images/svg/Moons.svg";

export function Footer() {

  return (
    <>
      <div className="Moons">
        <ScrollAnimation animateIn="animate__slideInUp">
          <img src={Moons} alt="mooons" />
        </ScrollAnimation>
      </div>
      <footer className="footer">
        <div className="footer__container container">
          <h1 className="footer__title">#ericmonroy</h1>
          <p className="footer__description">
            Soy <b>Eric</b> y este es mi portafolio web personal, puedes
            consultarme en el siguiente enlace.
          </p>

          <div className="footer__social">
            <a href="https://www.linkedin.com/in/eric-monroy-7321a037/" rel="noopener noreferrer" className="footer__link" target="_blank">
              <LinkedInIcon></LinkedInIcon>
            </a>
{/*             <Link to="/ContactMe" className="footer__link">
              <EmailIcon></EmailIcon>
            </Link> */}
          </div>
          <p className="footer__copy">
            &#169; 2021 ericmonroy. All right reserved.<br></br> Made in Love
            <FavoriteIcon className="footer__icon" />
          </p>
        </div>
      {/*   <Link to="/curriculum">
          <button className="button_cv">
            ver cv
          </button>
        </Link> */}
        {/* <div className="mentions__content">
          <p>Este sitio fue construido gracias a los recursos de open source los cuales hago mención.</p>
          <small>ReactJS: https://es.reactjs.org/</small>
          <small>Material UI: https://material-ui.com/</small>
          <small>React Training: https://reacttraining.com/</small>
          <small>Illlustrations https://illlustrations.co/</small>
          <small>ManyPixels  https://www.manypixels.co/</small>
          <small>Undraw https://undraw.co/</small>
          <small>FlatIcon https://www.flaticon.com/</small>
          <small>Netlify https://app.netlify.com/</small>
        </div> */}
      </footer>
    </>
  );
}
