import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Container, Divider, Grid } from "@material-ui/core";
import { NavBar } from "../components/Navbar/NavBar";
import { RedirectLink } from "../components/utils/Redirect.js";
import LaunchIcon from '@material-ui/icons/Launch';
import webapp from '../images/webapps/modal_compra_micropack.jpg';
import memorama from '../images/webapps/memorama1.jpg';
import realtime from '../images/webapps/realTime.jpg';



export function Webapps() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <NavBar />
            <div className="Push"></div>
            <Container maxWidth="lg">

                <div className="webapp__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="webapp_description">
                                <h3 className="webapp_title">Webapp Weex Mobile</h3>
                                <p className="webapp_texts">Proyecto webapp para contratación de
                                paquetes de megas y minutos para tu red celular, recargar de saldo con tarjeta de crédito,
                                pausar datos para evitar consumo, tranferencia de saldo a otro usuario de la misma compañía,
                                así como revisión de estado de cuenta sobre el consumo.
                                </p>
                                <br></br>
                                <label className="webapp_tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Sass</li>
                                        <li>Jquery</li>
                                        <li>JavaScript</li>
                                        <li>Ajax</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                        <li>Facebook API</li>
                                        <li>Conekta API</li>
                                        <li>Zendesk Chat</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="webapp_button" to={{ pathname: RedirectLink[0].url }} target="_blank" role="button">ver
                                    <LaunchIcon className="webapp__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={webapp} alt="webapp" className="webapp__weex" />
                        </Grid>
                    </Grid>
                </div>
                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>
                <div className="webapp__container">
                    <Grid container spacing={2} direction="row" id="memorama">
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={memorama} alt="webapp" className="webapp__memorama" />
                        </Grid>
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="webapp_description">
                                <h3 className="webapp_title">Game Memorama Hunters</h3>
                                <p className="webapp_texts">Juego de Memorama para Hunters Media, basado
                                en el gobierno de Enrique Peña Nieto, al encontrar el par de cada tarjeta muestra un modal
                                con la información de la mala administración de su gobierno.
                                </p>
                                <br></br>
                                <label className="webapp_tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Jquery</li>
                                        <li>JavaScript</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="webapp_button" to={{ pathname: RedirectLink[1].url }} target="_blank" role="button">ver
                                    <LaunchIcon className="webapp__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>

                    </Grid>
                </div>

                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>
                <div className="webapp__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="webapp_description">
                                <h3 className="webapp_title">KPI´s Weex</h3>
                                <p className="webapp_texts">
                                    Dashboard que visualiza el estatus de solicitudes de venta y envío de producto en gráfica,
                                    así como un mapa de calor donde se registra en donde se han hecho solicitudes del producto.
                                </p>
                                <br></br>
                                <label className="webapp_tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Jquery</li>
                                        <li>Html</li>
                                        <li>Css</li>
                                        <li>Google Maps</li>
                                        <li>Twitter</li>
                                        <li>Weex API</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={realtime} alt="webapp" className="webapp__realtime" />
                        </Grid>
                    </Grid>
                </div>
                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>
            </Container>
        </div>
    )
}