import React from "react";
import { Grid, Container } from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import WebIcon from "@material-ui/icons/Web";
import WebAssetRoundedIcon from "@material-ui/icons/WebAssetRounded";
import satellite from "../../images/svg/satellite.svg";
import ScrollAnimation from "react-animate-on-scroll";
import server from "../../images/svg/server.svg";
import webapp from "../../images/svg/webapp.svg";
import landingpage from "../../images/svg/landingpage.svg";
import chatbot from "../../images/svg/chatbot.svg";

import AdbRoundedIcon from "@material-ui/icons/AdbRounded";
export function Services() {
  return (
    <section className="services section" id="services">
      <ScrollAnimation animateIn="animate__fadeIn">
        <Container fixed>
          <div className="section-title">
            <img src={satellite} className="section-icon" alt="spaceman" />
            <h2>Servicios</h2>
          </div>

          <div className="services__container">
            <Grid container spacing={3} direction="row">
              <Grid item md={3} sm={12} xs={12}>
                <div className="services__data">
                  <div className="services__icon_content">
                    <WebAssetRoundedIcon className="services__icon"></WebAssetRoundedIcon>
                  </div>
                  <h3 className="services__title">Sitios web</h3>
                  <img
                    src={landingpage}
                    alt="server"
                    className="services__img"
                  />
                </div>
              </Grid>

              <Grid item md={3} sm={12} xs={12}>
                <div className="services__data">
                  <div className="services__icon_content">
                    <WebIcon className="services__icon"></WebIcon>
                  </div>
                  <h3 className="services__title">Aplicación web</h3>
                  <img src={webapp} alt="server" className="services__img" />
                </div>
              </Grid>

              <Grid item md={3} sm={12} xs={12}>
                <div className="services__data">
                  <div className="services__icon_content">
                    <CodeIcon className="services__icon"></CodeIcon>
                  </div>
                  <h3 className="services__title">Servicios web</h3>
                  <img src={server} alt="server" className="services__img" />
                </div>
              </Grid>

              <Grid item md={3} sm={12} xs={12}>
                <div className="services__data">
                  <div className="services__icon_content">
                    <AdbRoundedIcon className="services__icon"></AdbRoundedIcon>
                  </div>
                  <h3 className="services__title">
                    Bots
                  </h3>
                  <img src={chatbot} alt="server" className="services__img" />
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </ScrollAnimation>
    </section>
  );
}
