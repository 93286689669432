import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ScrollToTop } from "./components/utils/scrollTop";
import { Home } from './pages/Home';
import { NotFound } from "./pages/NotFound";
import { Dashboard } from "./pages/dashboard";
import { Webapps } from "./components/webapps";
import { Crm } from "./components/crm";
import { Bots } from "./components/bots";
import { landingpage } from "./components/landingpages";
import { Practiques } from "./components/practiques";
import { Cv } from "./components/cv";
import { ContactMe } from "./components/contact";

import './scss/Main.scss';


export default function App() {

  return (
    <Router>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/webapps" component={Webapps} />
          <Route path="/crm" component={Crm} />
          <Route path="/bots" component={Bots} />
          <Route path="/landingpages" component={landingpage} />
          <Route path="/apps" component={Practiques} />
          <Route path="/curriculum" component={Cv} />
          <Route path="/ContactMe" component={ContactMe} />
          <Route path="*" component={NotFound} />
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </Router>
    </Router>
  );

}

