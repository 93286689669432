import React from 'react';
import { Container, Divider, Grid } from "@material-ui/core";
import { NavBar } from "../components/Navbar/NavBar";
import Crm0 from "../images/crm/crm.jpg";
import Crm1 from "../images/crm/crm1.jpg";
import Crm2 from "../images/crm/crm2.jpg";
import Crm4 from "../images/crm/crm4.jpg";
import Crm5 from "../images/crm/crm5.jpg";

export function Crm() {

    return (
        <div>
            <NavBar />
            <div className="Push"></div>
            <Container maxWidth="lg">
                <div className="crm__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="crm__description">
                                <h3 className="crm__title">CRM Mibo</h3>
                                <p className="crm__texts">Plataforma de control de procesos, tales como movimientos en recargas, disperciones,
                                abonos, solicitudes de KYC, detalle de usuarios, panel de administración de personal, gestor de campañas,
                                validación de agente, manejo de tokens, tiempo de sesión y registro de actividades.</p>
                                <br></br>
                                <label className="crm__tecnologys">Tecnologías usadas:
                                        <ul>
                                        <li>Html5</li>
                                        <li>Css</li>
                                        <li>Sass</li>
                                        <li>Webpack</li>
                                        <li>Jquery</li>
                                        <li>JavaScript</li>
                                        <li>Ajax</li>
                                        <li>Mibo API</li>
                                        <li>JWT</li>
                                    </ul>
                                </label>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={Crm0} alt="..." className="crm__mibo_img" />
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Grid container spacing={2} direction="row" className="crm__img_grid">
                        <Grid item md={6} sm={12} xs={12}>
                            <p>Panel de administración</p>
                            <img src={Crm1} alt="..." />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <p>Gestor de usuarios</p>
                            <img src={Crm2} alt="..." />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <p>Panel de agentes</p>
                            <img src={Crm4} alt="..." />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <p>Panel de logística</p>
                            <img src={Crm5} alt="..." />
                        </Grid>
                    </Grid>
                </div>
                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>

            </Container>
        </div>
    )
}
