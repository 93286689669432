import React from 'react';
import { Link } from "react-router-dom";
import { Container, Divider, Grid } from "@material-ui/core";
import { RedirectLink } from "../components/utils/Redirect.js";
import { NavBar } from "../components/Navbar/NavBar";
import landingmibo from "../images/landing/landing-mibo.jpg";
import landingweex from "../images/landing/landing-weex.jpg";
import destapp from "../images/landing/destapp1.jpg";
import americasinmuros from "../images/landing/americasinmuros.jpg";
import blackshell from "../images/landing/blackshell.jpg";
import globafin from "../images/landing/globafin.jpg";
import LaunchIcon from '@material-ui/icons/Launch';

export function landingpage() {
    return (
        <div>
            <NavBar />
            <div className="Push"></div>
            <Container maxWidth="lg">

                <div className="landing__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="landing__description">
                                <h3 className="landing__title" style={{ color: "#000000" }}>Mibo</h3>
                                <p className="landing__texts">Sitio web oficial Mibo del ramo bursatil tales características como:
                                solicitud de crédito, solicitud de tarjeta física, depositos en diferentes tiendas así como promociones.

                                </p>
                                <br></br>
                                <label className="landing__tecnologys">Tecnologías usadas:
                                        <ul>
                                        <li>Sass</li>
                                        <li>Jquery</li>
                                        <li>JavaScript</li>
                                        <li>Ajax</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                        <li>Webpack</li>
                                        <li>Zendesk Chat</li>
                                        <li>Facebook API</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="landing__button_pages" to={{ pathname: RedirectLink[2].url }} target="_blank" role="button">ver
                                        <LaunchIcon className="landing__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={landingmibo} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>

                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>

                <div className="landing__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="landing__description">
                                <h3 className="landing__title" style={{ color: "#000000" }}>Weex Mobile</h3>
                                <p className="landing__texts">Sitio web Weex Mobile empresa
                                de telecomunicaciones, ventas de paquetes de megas y minutos, mapa de cobertura,
                                tiendas de recarga y compra de producto.
                                </p>
                                <br></br>
                                <label className="landing__tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Sass</li>
                                        <li>Jquery</li>
                                        <li>JavaScript</li>
                                        <li>Ajax</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                        <li>Zendesk Chat</li>
                                        <li>Facebook API</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="landing__button_pages" to={{ pathname: RedirectLink[3].url }} target="_blank" role="button">ver
                                        <LaunchIcon className="landing__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={landingweex} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>

                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>

                <div className="landing__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="landing__description">
                                <h3 className="landing__title" style={{ color: "#000000" }}>Destapp Coca-Cola</h3>
                                <p className="landing__texts">Proyecto Coca Cola y weex.
                                realizando formulario de solicitud de SIM canjeando
                                tu código otorgado por Coca Cola.</p>
                                <br></br>
                                <label className="landing__tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>JavaScript</li>
                                        <li>Ajax</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                    </ul>
                                </label>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={destapp} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>

                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>


                <div className="landing__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="landing__description">
                                <h3 className="landing__title" style={{ color: "#000000" }}>America Sin Muros</h3>
                                <p className="landing__texts">Sitio web y Blog de información y ayuda para personas migrantes
                                de LATAM.</p>
                                <br></br>
                                <label className="landing__tecnologys">Tecnologías usadas:
                                        <ul>
                                        <li>Php</li>
                                        <li>MySql</li>
                                        <li>Wordpress</li>
                                        <li>JavaScript</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="landing__button_pages" to={{ pathname: RedirectLink[4].url }} target="_blank" role="button">ver
                                        <LaunchIcon className="landing__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={americasinmuros} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>

                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>

                <div className="landing__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="landing__description">
                                <h3 className="landing__title" style={{ color: "#000000" }}>BlackShell</h3>
                                <p className="landing__texts">Sitio web para reservación de Lofts en Playa del Carmen
                                mediante formulario de contacto y llamada.
                                    </p>
                                <br></br>
                                <label className="landing__tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Jquery</li>
                                        <li>JavaScript</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                        <li>Google Maps</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="landing__button_pages" to={{ pathname: RedirectLink[5].url }} target="_blank" role="button">ver
                                        <LaunchIcon className="landing__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={blackshell} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>

                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>


                <div className="landing__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="landing__description">
                                <h3 className="landing__title" style={{ color: "#000000" }}>Globafin</h3>
                                <p className="landing__texts">Sitio web de empresa de sector financiero,
                                brindando soluciones integrales de acuerdo al perfil y necesidades financieras de cada cliente.
                                    </p>
                                <br></br>
                                <label className="landing__tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Jquery</li>
                                        <li>JavaScript</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                        <li>Google Maps</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="landing__button_pages" to={{ pathname: RedirectLink[6].url }} target="_blank" role="button">ver
                                        <LaunchIcon className="landing__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={globafin} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>

            </Container>
        </div>
    )
}
