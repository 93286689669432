import React, { Component } from 'react';
import { Container } from "@material-ui/core";
import { NavBar } from "../components/Navbar/NavBar";

export class NotFound extends Component {
    render() {
        return (
            <>
                <NavBar />
                <Container>
                    <div className="notFound__content">
                        <h1 className="title"> 404 - Página no encontrada </h1>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 300" className="illustration">
                            <g id="_145_404_page_not_found_outline" data-name="#145_404_page_not_found_outline">
                                <rect x="141.8" y="81.93" width="118.53" height="139.36" rx="5.54" fill="#fff"></rect>
                                <path d="M254.8,222.3H147.34a6.56,6.56,0,0,1-6.54-6.54V87.47a6.55,6.55,0,0,1,6.54-6.53H254.8a6.55,6.55,0,0,1,6.54,6.53V215.76A6.56,6.56,0,0,1,254.8,222.3ZM147.34,82.94a4.54,4.54,0,0,0-4.54,4.53V215.76a4.55,4.55,0,0,0,4.54,4.54H254.8a4.55,4.55,0,0,0,4.54-4.54V87.47a4.54,4.54,0,0,0-4.54-4.53Z" fill="#093f68"></path>
                                <path d="M147.34,81.93H254.8a5.54,5.54,0,0,1,5.54,5.54v9.67a0,0,0,0,1,0,0H141.8a0,0,0,0,1,0,0V87.47A5.54,5.54,0,0,1,147.34,81.93Z" fill="#68e1fd"></path><path d="M260.34,98.15H141.8a1,1,0,0,1-1-1V87.47a6.55,6.55,0,0,1,6.54-6.53H254.8a6.55,6.55,0,0,1,6.54,6.53v9.68A1,1,0,0,1,260.34,98.15Zm-117.54-2H259.34V87.47a4.54,4.54,0,0,0-4.54-4.53H147.34a4.54,4.54,0,0,0-4.54,4.53Z" fill="#093f68"></path>
                                <circle cx="151.87" cy="89.21" r="2.46" fill="#093f68"></circle>
                                <circle cx="158.71" cy="89.21" r="2.46" fill="#093f68"></circle>
                                <circle cx="165.54" cy="89.21" r="2.46" fill="#093f68"></circle>
                                <rect x="123.96" y="129.34" width="60.17" height="20.69" rx="2.42" fill="#ffbc0e"></rect>
                                <path d="M181.7,151H126.38A3.43,3.43,0,0,1,123,147.6V131.76a3.43,3.43,0,0,1,3.42-3.43H181.7a3.43,3.43,0,0,1,3.42,3.43V147.6A3.43,3.43,0,0,1,181.7,151Zm-55.32-20.7a1.43,1.43,0,0,0-1.42,1.43V147.6a1.43,1.43,0,0,0,1.42,1.43H181.7a1.43,1.43,0,0,0,1.42-1.43V131.76a1.43,1.43,0,0,0-1.42-1.43Z" fill="#093f68"></path>
                                <path d="M130.57,139.68a5.33,5.33,0,0,1,.38-2,4.53,4.53,0,0,1,1.05-1.55,4.59,4.59,0,0,1,1.6-1,6.2,6.2,0,0,1,4.05,0,4.59,4.59,0,0,1,1.6,1,4.53,4.53,0,0,1,1.05,1.55,5.67,5.67,0,0,1,0,4.08,4.34,4.34,0,0,1-1.05,1.55,4.59,4.59,0,0,1-1.6,1,6.2,6.2,0,0,1-4.05,0,4.59,4.59,0,0,1-1.6-1,4.34,4.34,0,0,1-1.05-1.55A5.33,5.33,0,0,1,130.57,139.68Zm2.14,0a3.34,3.34,0,0,0,.21,1.21,2.63,2.63,0,0,0,.59,1,2.6,2.6,0,0,0,.92.63,3.22,3.22,0,0,0,2.39,0,2.6,2.6,0,0,0,.92-.63,2.63,2.63,0,0,0,.59-1,3.56,3.56,0,0,0,0-2.41,2.77,2.77,0,0,0-.59-1,2.6,2.6,0,0,0-.92-.63,3.36,3.36,0,0,0-2.39,0,2.6,2.6,0,0,0-.92.63,2.77,2.77,0,0,0-.59,1A3.36,3.36,0,0,0,132.71,139.68Z" fill="#093f68"></path>
                                <path d="M142.9,139.68a5.33,5.33,0,0,1,.38-2,4.53,4.53,0,0,1,1.05-1.55,4.59,4.59,0,0,1,1.6-1,6.2,6.2,0,0,1,4,0,4.59,4.59,0,0,1,1.6,1,4.53,4.53,0,0,1,1,1.55,5.67,5.67,0,0,1,0,4.08,4.34,4.34,0,0,1-1,1.55,4.59,4.59,0,0,1-1.6,1,6.2,6.2,0,0,1-4,0,4.59,4.59,0,0,1-1.6-1,4.34,4.34,0,0,1-1.05-1.55A5.33,5.33,0,0,1,142.9,139.68Zm2.14,0a3.34,3.34,0,0,0,.21,1.21,2.63,2.63,0,0,0,.59,1,2.6,2.6,0,0,0,.92.63,3,3,0,0,0,1.19.23,3.09,3.09,0,0,0,1.2-.23,2.6,2.6,0,0,0,.92-.63,2.63,2.63,0,0,0,.59-1,3.73,3.73,0,0,0,0-2.41,2.77,2.77,0,0,0-.59-1,2.6,2.6,0,0,0-.92-.63,3.09,3.09,0,0,0-1.2-.22,3,3,0,0,0-1.19.22,2.6,2.6,0,0,0-.92.63,2.77,2.77,0,0,0-.59,1A3.36,3.36,0,0,0,145,139.68Z" fill="#093f68"></path>
                                <path d="M155.73,135h3.48a6.74,6.74,0,0,1,1.38.13,3.09,3.09,0,0,1,1.13.46,2.25,2.25,0,0,1,.77.87,3,3,0,0,1,.28,1.38,3.3,3.3,0,0,1-.26,1.38,2.29,2.29,0,0,1-.73.89,3.13,3.13,0,0,1-1.1.47,6.6,6.6,0,0,1-1.38.13h-1.5v3.66h-2.07Zm2.07,4h1.37a2.35,2.35,0,0,0,.54-.06,1.41,1.41,0,0,0,.46-.17.93.93,0,0,0,.33-.35,1,1,0,0,0,.13-.55.92.92,0,0,0-.17-.57,1.07,1.07,0,0,0-.42-.34,1.7,1.7,0,0,0-.58-.15l-.6,0H157.8Z" fill="#093f68"></path>
                                <path d="M170,137.29a1.46,1.46,0,0,0-.68-.47,2.49,2.49,0,0,0-.81-.15,1.93,1.93,0,0,0-.47.05,1.72,1.72,0,0,0-.45.16,1.1,1.1,0,0,0-.34.3.77.77,0,0,0-.13.45.73.73,0,0,0,.32.65,2.71,2.71,0,0,0,.8.38c.32.11.66.22,1,.32a4.61,4.61,0,0,1,1,.45,2.59,2.59,0,0,1,.8.78,2.4,2.4,0,0,1,.32,1.31,3,3,0,0,1-.29,1.36,2.77,2.77,0,0,1-.79,1,3.35,3.35,0,0,1-1.15.57,5.4,5.4,0,0,1-3.07-.09,4.42,4.42,0,0,1-1.46-.9l1.47-1.62a2.24,2.24,0,0,0,.8.66,2.32,2.32,0,0,0,1,.23,2.22,2.22,0,0,0,.51-.06,1.7,1.7,0,0,0,.46-.18,1,1,0,0,0,.33-.3.81.81,0,0,0,.13-.44.79.79,0,0,0-.33-.67,3.06,3.06,0,0,0-.81-.42l-1.06-.34a4.79,4.79,0,0,1-1.06-.46,2.59,2.59,0,0,1-.81-.77,2.18,2.18,0,0,1-.33-1.26,2.79,2.79,0,0,1,.3-1.32,2.84,2.84,0,0,1,.8-.95,3.41,3.41,0,0,1,1.14-.58,4.62,4.62,0,0,1,1.34-.19,5.2,5.2,0,0,1,1.53.23,3.64,3.64,0,0,1,1.34.75Z" fill="#093f68"></path>
                                <path d="M175.07,143.23a1.29,1.29,0,0,1,.1-.48,1.2,1.2,0,0,1,.65-.65,1.23,1.23,0,0,1,.47-.09,1.28,1.28,0,0,1,.48.09,1.16,1.16,0,0,1,.39.26,1.33,1.33,0,0,1,.26.39,1.28,1.28,0,0,1,.09.48,1.23,1.23,0,0,1-.09.47,1.33,1.33,0,0,1-.26.39,1.16,1.16,0,0,1-.39.26,1.28,1.28,0,0,1-.48.09,1.23,1.23,0,0,1-.47-.09,1.2,1.2,0,0,1-.65-.65A1.24,1.24,0,0,1,175.07,143.23Zm2.21-2h-2V135h2Z" fill="#093f68"></path><path d="M184.06,205.19H125a2.83,2.83,0,0,1-2.29-4.5l29.54-40.52a2.83,2.83,0,0,1,4.57,0l29.54,40.52A2.83,2.83,0,0,1,184.06,205.19Z" fill="#ffbc0e"></path>
                                <path d="M184.06,206.19H125a3.83,3.83,0,0,1-3.1-6.09l29.54-40.52a3.84,3.84,0,0,1,6.19,0l29.54,40.52a3.83,3.83,0,0,1-3.1,6.09ZM154.53,160a1.78,1.78,0,0,0-1.48.75l-29.54,40.52a1.83,1.83,0,0,0,1.48,2.91h59.07a1.83,1.83,0,0,0,1.48-2.91L156,160.76A1.81,1.81,0,0,0,154.53,160Z" fill="#093f68"></path>
                                <path d="M141.68,193.09h-5.47v-2.23l5.12-7.79h2.9v7.79h1.61v2.23h-1.61v2.52h-2.55Zm0-6.55h0l-2.78,4.32h2.82Z" fill="#093f68"></path>
                                <path d="M148.63,189.34a12.59,12.59,0,0,1,.2-2.27,6.4,6.4,0,0,1,.73-2.09,4.17,4.17,0,0,1,1.42-1.53,4.73,4.73,0,0,1,4.61,0A4.17,4.17,0,0,1,157,185a6.4,6.4,0,0,1,.73,2.09,13,13,0,0,1,0,4.54,6.57,6.57,0,0,1-.73,2.09,4.33,4.33,0,0,1-1.42,1.53,4.8,4.8,0,0,1-4.61,0,4.33,4.33,0,0,1-1.42-1.53,6.57,6.57,0,0,1-.73-2.09A12.67,12.67,0,0,1,148.63,189.34Zm2.65,0c0,.33,0,.72.05,1.18a5.79,5.79,0,0,0,.24,1.33,2.69,2.69,0,0,0,.59,1.08,1.62,1.62,0,0,0,2.24,0,2.71,2.71,0,0,0,.6-1.08,5.79,5.79,0,0,0,.24-1.33c0-.46,0-.85,0-1.18s0-.73,0-1.19a5.89,5.89,0,0,0-.24-1.33,2.85,2.85,0,0,0-.6-1.08,1.65,1.65,0,0,0-2.24,0,2.83,2.83,0,0,0-.59,1.08,5.89,5.89,0,0,0-.24,1.33C151.3,188.61,151.28,189,151.28,189.34Z" fill="#093f68"></path>
                                <path d="M166.2,193.09h-5.47v-2.23l5.12-7.79h2.9v7.79h1.61v2.23h-1.61v2.52H166.2Zm0-6.55h0l-2.79,4.32h2.82Z" fill="#093f68"></path><polygon points="277.85 204.85 194.48 204.85 194.48 132.12 206.53 117.51 277.85 117.51 277.85 204.85" fill="#68e1fd"></polygon><path d="M277.85,205.85H194.48a1,1,0,0,1-1-1V132.12a1,1,0,0,1,.23-.64l12-14.61a1,1,0,0,1,.77-.36h71.32a1,1,0,0,1,1,1v87.34A1,1,0,0,1,277.85,205.85Zm-82.37-2h81.37V118.51H207l-11.52,14Z" fill="#093f68"></path>
                                <path d="M211.46,156.21a1,1,0,0,1-.71-1.71l12.77-12.83a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42l-12.76,12.83A1,1,0,0,1,211.46,156.21Z" fill="#093f68"></path>
                                <path d="M224.23,156.21a1,1,0,0,1-.71-.3l-12.77-12.83a1,1,0,1,1,1.42-1.41l12.76,12.83a1,1,0,0,1,0,1.41A1,1,0,0,1,224.23,156.21Z" fill="#093f68"></path>
                                <path d="M250.77,156.21a1,1,0,0,1-.7-.3,1,1,0,0,1,0-1.41l12.77-12.83a1,1,0,1,1,1.42,1.41l-12.77,12.83A1,1,0,0,1,250.77,156.21Z" fill="#093f68"></path>
                                <path d="M263.54,156.21a1,1,0,0,1-.71-.3l-12.77-12.83a1,1,0,0,1,0-1.42,1,1,0,0,1,1.41,0l12.77,12.83a1,1,0,0,1-.71,1.71Z" fill="#093f68"></path>
                                <path d="M230.65,169.7H242a0,0,0,0,1,0,0v10a5.67,5.67,0,0,1-5.67,5.67h0a5.67,5.67,0,0,1-5.67-5.67v-10A0,0,0,0,1,230.65,169.7Z" fill="#f56132"></path><path d="M236.32,186.41a6.68,6.68,0,0,1-6.67-6.67v-10a1,1,0,0,1,1-1H242a1,1,0,0,1,1,1v10A6.68,6.68,0,0,1,236.32,186.41Zm-4.67-15.71v9a4.67,4.67,0,1,0,9.34,0v-9Z" fill="#093f68"></path><path d="M236.32,178.55a1,1,0,0,1-1-1V169.7a1,1,0,0,1,2,0v7.85A1,1,0,0,1,236.32,178.55Z" fill="#093f68"></path><path d="M263.54,170.7H211.46a1,1,0,1,1,0-2h52.08a1,1,0,0,1,0,2Z" fill="#093f68"></path><path d="M200.74,108.33H151.43a1,1,0,0,1,0-2h49.31a1,1,0,0,1,0,2Z" fill="#dfeaef"></path><polygon points="194.48 132.12 206.4 132.12 206.53 117.51 194.48 132.12" fill="#fff"></polygon>
                                <path d="M206.4,133.12H194.48a1,1,0,0,1-.91-.58,1,1,0,0,1,.14-1.06l12-14.61a1,1,0,0,1,1.77.64l-.13,14.62A1,1,0,0,1,206.4,133.12Zm-9.8-2h8.81l.1-10.8Z" fill="#093f68"></path>
                                <path d="M221.43,108.33h-10a1,1,0,0,1,0-2h10a1,1,0,0,1,0,2Z" fill="#dfeaef"></path>
                                <path d="M181.83,120.7h-30a1,1,0,0,1,0-2h30a1,1,0,0,1,0,2Z" fill="#dfeaef"></path>
                                <path d="M181.83,164.16H171.45a1,1,0,0,1,0-2h10.38a1,1,0,0,1,0,2Z" fill="#dfeaef"></path>
                                <path d="M181.83,174.62h-6.76a1,1,0,0,1,0-2h6.76a1,1,0,1,1,0,2Z" fill="#dfeaef"></path>
                                <path d="M247.51,108.33H230.65a1,1,0,0,1,0-2h16.86a1,1,0,0,1,0,2Z" fill="#dfeaef"></path>
                            </g>
                        </svg>
                        <br></br>
                    </div>
                </Container>
            </>
        )
    }
}
