import React from "react";
import { Link } from "react-router-dom";
import { Paper, Grid, Container } from "@material-ui/core";
import backoffice from "../../images/BackOffice_Portada.jpg";
import ArrowRight from "@material-ui/icons/ArrowRight";
import webapp from "../../images/webapp.jpg";
import hunters from "../../images/hunters.jpg";
import bot from "../../images/Bot.jpg";
import wallet from "../../images/wallet.jpg";
import server from "../../images/server.jpg";
import space from "../../images/svg/spaceship.svg";

export function Portfolio() {
  return (
    <section className="portfolio">
      <Container fixed>
        <div className="section-title">
          <img src={space} className="section-icon" alt="spaceman" />
          <h2>Portafolio</h2>
          <b className="section-subtitle">Trabajos recientes</b>
        </div>

        <div className="portfolio__container">
          <Grid container spacing={3} direction="row">
            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <div className="portfolio__content">
                  <Link to="/crm">
                    <img className="portfolio__img" alt="" src={backoffice} />
                  </Link>
                  <div className="portfolio__data">
                    <span className="portfolio__subtitle">CRM</span>
                    <h2 className="portfolio__title">
                      Desarrollo e implementación de CRM de Mibo para uso
                      interno la empresa.
                    </h2>
                    <Link to="/crm" className="button-link">
                      <ArrowRight className="about__icon"></ArrowRight> ver
                      detalles
                    </Link>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <div className="portfolio__content">
                  <Link to="/webapps">
                    <img className="portfolio__img" alt="" src={webapp} />
                  </Link>
                  <div className="portfolio__data">
                    <span className="portfolio__subtitle">WebApps</span>
                    <h2 className="portfolio__title">
                      Desarrollo de aplicación webapp para compra de paquetes de
                      megas y minutos con <b>Weex Mobile</b>.
                    </h2>
                    <Link to="/webapps" className="button-link">
                      <ArrowRight className="about__icon"></ArrowRight> ver
                      detalles
                    </Link>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <div className="portfolio__content mix ux">
                  <Link to="/webapps">
                    <img className="portfolio__img" alt="" src={hunters} />
                  </Link>
                  <div className="portfolio__data">
                    <span className="portfolio__subtitle">Game</span>
                    <h2 className="portfolio__title">
                      Juego de Memorama para <b>Hunters Media</b> sobre el
                      gobierno de Peña Nieto.
                    </h2>
                    <Link to="/webapps#memorama" className="button-link">
                      <ArrowRight className="about__icon"></ArrowRight> ver
                      detalles
                    </Link>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <div className="portfolio__content mix ux">
                  <Link to="/bots">
                    <img className="portfolio__img" alt="" src={bot} />
                  </Link>
                  <div className="portfolio__data">
                    <span className="portfolio__subtitle">Bot</span>
                    <h2 className="portfolio__title">
                      Desarrollo de Bot web para solicitud de crédito bancario.{" "}
                    </h2>
                    <Link to="/bots" className="button-link">
                      <ArrowRight className="about__icon"></ArrowRight> ver
                      detalles
                    </Link>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <div className="portfolio__content mix app">
                  <Link to="/landingpages">
                    <img className="portfolio__img" alt="" src={wallet} />
                  </Link>
                  <div className="portfolio__data">
                    <span className="portfolio__subtitle">Landing Pages</span>
                    <h2 className="portfolio__title">
                      Creación de sitios web responsivos, E-commerce , Mini
                      sitios para compra de producto o campañas publicitarias.
                    </h2>
                    <Link to="/landingpages" className="button-link">
                      <ArrowRight className="about__icon"></ArrowRight> ver
                      detalles
                    </Link>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <div className="portfolio__content mix app">
                  <Link to="/landingpages">
                    <img className="portfolio__img" alt="" src={server} />
                  </Link>
                  <div className="portfolio__data">
                    <span className="portfolio__subtitle">API</span>
                    <h2 className="portfolio__title">
                      Desarrollo de servicios web con NodeJs de protocolo Rest
                      para aplicaciones móviles y webapps.
                    </h2>
                    <Link to="/landingpages" className="button-link">
                      <ArrowRight className="about__icon"></ArrowRight> ver
                      detalles
                    </Link>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
}
