import React from "react";
import { Footer } from "../components/contentDashboard/footer";
import { NavBar } from "../components/Navbar/NavBar";
import Grid from "@material-ui/core/Grid";
import { About } from "../components/contentDashboard/about";
import { Portfolio } from "../components/contentDashboard/portfolio";
import { Services } from "../components/contentDashboard/services";
import { ContactMe } from "../components/contact";
import ScrollAnimation from "react-animate-on-scroll";

export function Dashboard() {
  return (
    <Grid container spacing={0}>
      <div className="main-panel">
        <NavBar />
        <About />
        <ScrollAnimation animateIn="animate__fadeIn">
          <Portfolio />
        </ScrollAnimation>
        <Services />
        <ContactMe/>
        <Footer />
      </div>
    </Grid>
  );
}
