import React from 'react';
import { Link } from "react-router-dom";
import {
    Container, Paper
} from "@material-ui/core";
import ovni from "../images/svg/nave-alienigena.svg";
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import PhoneIphoneRoundedIcon from '@material-ui/icons/PhoneIphoneRounded';
import PinDropRoundedIcon from '@material-ui/icons/PinDropRounded';
export function Cv() {
    return (
        <>
            <Container fixed>
                <Paper>
                    <div className="cv__main">
                        <div className="cv__title">
                            <h1>Eric A. Monroy Gasca</h1>
                            <img src={ovni} alt="" />
                        </div>
                        <p className="cv__profile">[ Desarrollador de Tecnologías Web ]</p>
                        <br></br>
                        <p className="cv__description">Desarrollador web con diez años de experiencia en el ramo de aplicaciones web trabajando
                        en áreas de Fábrica de software, testing, diseño web y mobile. Así como para sectores
                        financieros, telecomunicaciones y marketing.
                        </p>
                        <br></br>
                        <div className="cv__data">
                            <ul>
                                <li><MailOutlineRoundedIcon></MailOutlineRoundedIcon><p>ericmonroygasca@gmail.com</p></li>
                                <li><PhoneIphoneRoundedIcon></PhoneIphoneRoundedIcon><p>5514103024</p></li>
                                <li><PinDropRoundedIcon></PinDropRoundedIcon><p>Ciudad de México MX</p></li>
                            </ul>
                        </div>
                        <br></br>
                        <div className="cv__line"></div>
                        <br></br>

                        <div className="cv__main__content">
                            <div className="cv__content">
                                <h4>HABILIDADES.</h4>
                                <ul>
                                    <li>
                                        <p>Desarrollo de páginas y aplicaciones
                                        web, correos electrónicos, desarrollo
                                        de Bots, aplicaciones progresivas y
                                        aplicaciones multiplataforma.</p>
                                    </li>
                                </ul>

                                <h4>EXPERIENCIA LABORAL.</h4>
                                <b>Mibo 11/2019 - 06/2020</b>
                                <br></br>
                                <small>Manager de Desarrollo Web</small>
                                <br></br>
                                <ul>
                                    <li><p>Responsable del área de frontend.
                                    Desarrolle página web corporativa.
                                    Encargado de construir y mantener
                                    aplicación CRM para uso interno de la
                                    misma, contruí un ChatBot para
                                    solicitud de crédito enbebido en
                                    webview para uso en aplicación movil.
                                    asi como maquetar correos
                                    electrónicos y hacer pruebas de
                                    funcionales de los productos.</p></li>
                                </ul>
                                <br></br>
                                <b>Weex Mobile y Wallet 03/2015 - 10/2019</b>
                                <br></br>
                                <small>Desarrollador Web</small>
                                <br></br>
                                <ul>
                                    <li><p>Desarrollo de página web corporativa.
                                    Creación de CRM para atención al
                                    cliente. Desarrollo de aplicación
                                    webapp para uso en dispositivos
                                    móviles y de escritorio.
                                    Generar pruebas funcionales para
                                    aplicación móvil
                                    Bot de facebook para solicitud de
                                    producto.
                                    Creación de minisitios para solicitud
                                    de créditos y solicitud de producto
                                    SIM.
                                    Soporte a levantamiento de
                                    incidencias del usuario.</p>
                                    </li>
                                </ul>

                                <h4>Freelance</h4>
                                <ul>
                                    <li>América sin muros:<p> Desarrollo de página web integrado con wordpress.</p></li>
                                    <li>Blackshell:<p> Página web para reservación de lofts.</p></li>
                                    <li>Capital-Más:<p> página web para reclutamiento.</p></li>
                                    <li>Globafin:<p> Página web para soluciones financieras.</p></li>
                                    <li>Tacofish:<p> Página web para restaurante de comida de mariscos.</p></li>
                                </ul>

                            </div>
                            <div className="cv__content">
                                <b>Hitss 11/2013 - 08/2014</b>
                                <br></br>
                                <small>Desarrollador Web & Tester</small>
                                <br></br>
                                <ul>
                                    <li>Cliente Mapfre:<p>Generar casos de uso,
                                    realizar levantamiento de incidencias,
                                    dar seguimiento a fallas, pruebas de
                                    estrés, rendimiento y ejecución.
                                    Pruebas unitarias con Junit para
                                    servicios web.</p>
                                    </li>
                                    <li>Cliente Bancomer:<p>Desarrollo de
                                    servicios web con Java, mapeo de
                                    stored procedures, creación de
                                    minisitios web.</p>
                                    </li>
                                    <li>Cliente Banorte:<p>Desarrollo de interfaz
                                    web para solicitud de seguros.</p>
                                    </li>
                                    <li>Fábrica de Software:<p>Desarrollo de
                                    aplicación móvil para el devengado
                                    de la empresa.</p>
                                    </li>
                                </ul>
                                <br></br>
                                <b>Hildebrando 02/2012 - 10/2013</b>
                                <br></br>
                                <small>Desarrollador Web & Mobile</small>
                                <br></br>
                                <ul>
                                    <li>Fábrica de Software:<p>Creación de
                                    plataforma web para el control de los
                                    proyectos, usuarios, documentación y
                                    gestión de incidencias. Desarrollo de
                                    aplicación móvil para realizar la
                                    captura de horas de trabajo.
                                    Generación de casos de uso,
                                    levantamiento de incidencias y
                                    pruebas funcionales.</p>
                                    </li>
                                </ul>

                                <br></br>
                                <b>Rayarte Editorial 04/2009 - 02/2012</b>
                                <br></br>
                                <small>Coordinador de Diseño Editorial y Web</small>
                                <br></br>
                                <ul>
                                    <li><p>Responsable de implementar y
                                    mantener CMS. Diseñar y construir
                                    sitios web así como crear banners y
                                    enviar sitio y revista a producción.</p>
                                    </li>
                                </ul>


                            </div>
                            <div className="cv__content">
                                <h4>APTITUDES.</h4>
                                <ul>
                                    <li>Manejo de servidores:<p>Apache Tomcat, Jetty, Netlify, Heroku.</p></li>
                                    <li>Control de versiones:<p>Git, Mercurial Hg, Svn.</p></li>
                                    <li>Sistemas Operativos:<p>Windows, Mac Osx.</p></li>
                                    <li>Bases de datos:<p>MySql, SQLite, MongoDB.</p></li>
                                    <li>Lenguajes y herramientas de desarrollo:
                                    <p>JavaScript, Java Web, Html5, Css, Sass, NodeJs, ReactJS, Angular 10,
                                        Jquery, Ftp, Wordpress, Titanium Studio, Spring Boot, Hibernate, Postman.
                                    </p>
                                    </li>
                                    <li>Herramientas de diseño que utilizo:<p> Adobe Photoshop, Illustrator, InDesign, Figma.</p></li>
                                    <li>Metodologías:<p>Rup, Scrum</p></li>
                                </ul>
                                <h4>SECTORES.</h4>
                                <ul>
                                    <li>
                                        <p>Bancario, Telefonía, Editorial, Consultoría en Tecnologías, Fintech.</p>
                                    </li>
                                </ul>
                                <h4>EDUCACIÓN.</h4>
                                <ul>
                                    <li>
                                        <p>
                                            Tecnológico de Estudios Superiores
                                            de Ecatepec.
                                            Ingeniería en Sistemas
                                            Computacionales. (2004 - 2008)
                                        </p>
                                    </li>
                                </ul>
                                <h4>EDUCACIÓN COMPLEMENTARIA.</h4>
                                <ul>
                                    <li><p>Master class NodeJS.  (Pirple).</p></li>
                                    <li><p>Desarrollo web eficiente workflows. (Udemy)</p></li>
                                    <li><p>Aplicaciones web progresivas. (Udemy)</p></li>
                                    <li><p>Curso de Sass. (Udemy)</p></li>
                                    <li><p>Aplicaciones web con ReactJs. (Udemy)</p></li>
                                    <li><p>Desarrollo de single page aplications con Angular. (Udemy)</p></li>
                                    <li><p>ECMA Script 6. (Udemy)</p></li>
                                    <li><p>Titanium Studio. (Appcelerator)</p></li>
                                    <li><p>Redes Informáticas. (Gobernación)</p></li>
                                    <li><p>Diseño editorial y web. (Editorial Rayarte)</p></li>
                                    <li><p>Diplomado en Java. (Tese)</p></li>
                                </ul>
                            </div>
                        </div>
                        <br></br>
                        <div className="cv__line"></div>
                        <br></br>
                        <div className="content__cv__button">
                            <a href="https://drive.google.com/drive/folders/1eqyNGy75HGz7ISU-emLkjDyi5khLMQjs?usp=sharing" rel="noopener noreferrer" className="footer__link" target="_blank">
                                <button className="button_cv">Download PDF</button>
                            </a>
                            <Link to="/dashboard"><button className="button_cv_back">Home</button></Link>
                        </div>
                    </div>
                </Paper>
            </Container>
        </>
    )
}