import React from "react";
import { useForm } from "react-hook-form";
import alien from "../images/svg/alien.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function Home(props) {
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    props.history.push("/dashboard");
  };

  return (
    <div className="Home">
      <div className="home__content">
        <ScrollAnimation animateIn="animate__slideInDown">
          <h1>
            Bienvenido a mi <strong>portafolio.</strong>
            <img src={alien} className="alien_icon" alt="alien" />
          </h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn">
          <form className="formHome" onSubmit={handleSubmit(onSubmit)}>
            <button className="BtnStyleBurn" type="submit">
              ¡Vamos!
            </button>
          </form>
        </ScrollAnimation>
      </div>
    </div>
  );
}
