
export const RedirectLink = [
    {
        name: "webapp",
        url: "https://weex.mx/webapp/"
    },
    {
        name: "memorama",
        url: "http://hunters.media/memoramaepn/"
    },
    {
        name: "mibolp",
        url: "https://mibo.mx/"
    },
    {
        name: "weexmx",
        url: "https://weex.mx/"
    },
    {
        name: "americasinmuros",
        url: "https://americasinmuros.org/"
    },
    {
        name: "blackshell",
        url: "http://blackshell.mx/"
    },
    {
        name: "globafin",
        url: "http://www.globafin.com/"
    },
    {
        name: "covid",
        url: "https://statuscovid.netlify.app"
    },
    {
        name: "paises",
        url: "https://countrys.netlify.app"
    },
    {
        name: "movies",
        url: "https://peliculasapi.netlify.app"
    }

];

