import React, { Component } from "react";
import { NavLink as Link } from "react-router-dom";
import { MenuItems } from "../utils/MenuItems";
import { FaBars, FaTimes } from "react-icons/fa";
import nave from "../../images/svg/nave-alienigena.svg";

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { clicked: false };
  }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <>
        <nav className="NavBarItems">
          <Link to="/">
            <h1 className="Navbar__logo">
              <img src={nave} alt="logo" className="Navbar__icon" />
            </h1>
          </Link>

          <div className="Menu__icon" onClick={this.handleClick}>
            <span>
              {this.state.clicked ? (
                <FaTimes className="faBars" />
              ) : (
                <FaBars className="faBars" />
              )}
            </span>
          </div>

          <ul className={this.state.clicked ? "Nav__menu active" : "Nav__menu"}>
            {MenuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link className={item.cName} to={item.url}>
                    {item.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
        <svg
          id="MainCurva"
          viewBox="0 0 1282 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M610.192 60.5861C404.909 119.195 116.5 102 0 77V1H1281V60.5861C1142.93 36.1655 815.476 1.97683 610.192 60.5861Z"
              fill="#742AF7"
              stroke="#742AF7"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="1282" height="100" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </>
    );
  }
}
