import React from 'react';
import { Link } from "react-router-dom";
import { Container, Divider, Grid } from "@material-ui/core";
import { NavBar } from "../components/Navbar/NavBar";
import { RedirectLink } from "../components/utils/Redirect.js";
import LaunchIcon from '@material-ui/icons/Launch';
import covid from "../images/landing/covid.jpg";
import paises from "../images/landing/paises.jpg";
import peliculas from "../images/landing/peliculas.jpg";


export function Practiques() {
    return (
        <div>
            <NavBar />
            <div className="Push"></div>
            <Container maxWidth="lg">

                <div className="webapp__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="webapp_description">
                                <h3 className="webapp_title">Covid Tracking</h3>
                                <p className="webapp_texts">Proyecto de visualización de estatus mundial en gráficas sobre el Covid-19.
                                </p>
                                <br></br>
                                <label className="webapp_tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>React JS</li>
                                        <li>Javascript ES6</li>
                                        <li>Rest API</li>
                                        <li>Fetch</li>
                                        <li>Css</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="webapp_button" to={{ pathname: RedirectLink[7].url }} target="_blank" role="button">ver
                                    <LaunchIcon className="webapp__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={covid} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>
                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>
                <div className="webapp__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="webapp_description">
                                <h3 className="webapp_title">Paises</h3>
                                <p className="webapp_texts">Proyecto de servicio de web países de LATAM y su información del país
                                </p>
                                <br></br>
                                <label className="webapp_tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Angular 10</li>
                                        <li>JavaScript ES6</li>
                                        <li>Fecth</li>
                                        <li>Rest API</li>
                                        <li>Css</li>
                                        <li>Html</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="webapp_button" to={{ pathname: RedirectLink[8].url }} target="_blank" role="button">ver
                                    <LaunchIcon className="webapp__icon"></LaunchIcon>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={paises} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>

                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>
                <div className="webapp__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="webapp_description">
                                <h3 className="webapp_title">Películas</h3>
                                <p className="webapp_texts">
                                    Proyecto sobre búsqueda de películas así como su detalle de las mismas.
                                </p>
                                <br></br>
                                <label className="webapp_tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>React JS</li>
                                        <li>Javascript ES6</li>
                                        <li>Rest API</li>
                                        <li>Fetch</li>
                                        <li>Css</li>
                                    </ul>
                                </label>
                                <br></br>
                                <br></br>
                                <Link className="webapp_button" to={{ pathname: RedirectLink[9].url }} target="_blank" role="button">ver
                                    <LaunchIcon className="webapp__icon"></LaunchIcon>
                                </Link>
                                <br></br>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={peliculas} alt="..." className="landing__mibo_img" />
                        </Grid>
                    </Grid>
                </div>
                <div className="Wrapper"></div>
                <Divider />
                <div className="Wrapper"></div>
            </Container>
        </div>
    )
}