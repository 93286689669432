import React from 'react';
import { Container, Divider, Grid, Paper } from "@material-ui/core";
import { NavBar } from "../components/Navbar/NavBar";
import Bot0 from "../images/Bot.jpg";
import Bot1 from "../images/bot/bot1.jpg";
import Bot2 from "../images/bot/bot2.jpg";
import Bot3 from "../images/bot/bot3.jpg";
import Bot4 from "../images/bot/bot4.jpg";
import Bot5 from "../images/bot/bot5.jpg";
import Bot6 from "../images/bot/bot6.jpg";

export function Bots() {

    return (
        <div>
            <NavBar />
            <div className="Push"></div>
            <Container maxWidth="lg">
                <div className="bot__container">
                    <Grid container spacing={2} direction="row">
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="bot__description">
                                <h3 className="bot__title">Chat Bot</h3>
                                <p className="bot__texts">Chat bot de solicitud de crédito Mibo - CredilikeMe. Flujo de preguntas y respuestas
                                acorde a los datos ingresados por el usuario para otorgar su solicitud de crédito.
                                 </p>
                                <br></br>
                                <label className="bot__tecnologys">Tecnologías usadas:
                                    <ul>
                                        <li>Html5</li>
                                        <li>Sass</li>
                                        <li>Webpack</li>
                                        <li>VueJS</li>
                                        <li>JavaScript</li>
                                        <li>Fetch</li>
                                        <li>Mibo API</li>
                                    </ul>
                                </label>
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <img src={Bot0} alt="..." className="bot__mibo_img" />
                        </Grid>
                    </Grid>
                    <div className="Wrapper"></div>
                    <Divider />
                    <div className="Wrapper"></div>
                    <Grid container spacing={3} direction="row" className="bot__img_grid">
                        <Grid item md={4} sm={12} xs={12} className="bot__img_content">
                            <Paper>
                                <img src={Bot1} alt="..." />
                            </Paper>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} className="bot__img_content">
                            <Paper>
                                <img src={Bot2} alt="..." />
                            </Paper>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} className="bot__img_content">
                            <Paper>
                                <img src={Bot3} alt="..." />
                            </Paper>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} className="bot__img_content">
                            <Paper>
                                <img src={Bot4} alt="..." />
                            </Paper>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} className="bot__img_content">
                            <Paper>
                                <img src={Bot5} alt="..." />
                            </Paper>
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} className="bot__img_content">
                            <Paper>
                                <img src={Bot6} alt="..." />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div className="Wrapper"></div>
            </Container>
        </div>
    )
}
